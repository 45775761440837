import { Vue, Component, Prop } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';


@Component({
  name: 'DropdownList',
  filters: {}
})
export default class DropdownList extends Vue {
    search: string = '';
    openDropdown: boolean = false;
    hovering: boolean= false;
    hoverText: string='';
    styleObject: any= {};

    @Prop()
    classes!: string[];

    @Prop({default: {id: 0, value: 'Select', label: '', error: false, errorText: '', search: '', dropdownList: [{id: 0, value: 'Select'}]}})
    dropdown!: {id: number; value: string; label?: string; error?: boolean; errorText?: string; disable?: boolean; search?: boolean; dropdownList: [{id: number; value: string}]};

    @Prop()
    slotObj!: {slotLabel: string; slotType: string};

    toggleDropdown(close?: boolean) {
      if (this.dropdown.disable) {
        return;
      }
      this.openDropdown = this.openDropdown
        ? APP_CONST.FALSE
        : (close
          ? APP_CONST.FALSE
          : APP_CONST.TRUE);
      this.clearSearch();
      this.hovering=!this.hovering;
    }

    select(selection: {id: number; value: string}) {
      if(selection && this.dropdown.id==selection.id){
        return;
      }
      this.dropdown.id = selection.id;
      this.dropdown.value = selection.value;
      this.$emit('onSelectionChange', {id: this.dropdown.id, value: this.dropdown.value});
      this.openDropdown = false;
      this.hovering=false;
    }

    filterList(list: [{id:number; value: string}], search: string): any {
      if(!search){
        return list;
      }
      const dropList = list.filter((el) => el.value.toLowerCase().includes(search.toLowerCase()));
      return dropList;
    }
    
    clearSearch() {
      this.search = '';
    }
    showTooltip(hoverText:string, event: any){
      this.hovering = true;
      this.hoverText=hoverText;
      const boundBox = event && event.target && event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.styleObject = {
        top:(coordY + 40).toString() + 'px',
        left:(coordX + 50).toString() + 'px'
      };
    }
    hideTooltip(){
      this.hovering = false;
      this.hoverText='';
    }
}